/* NAVBAR */
.pro-vendas-logo {
  max-width: 250px;
}

/* HEADER */

.header-div-buttons {
  padding-top: 30px;
  padding-right: 20px;
}

.header-btn {
  background-color: #121c3a;
  width: 200px;
  height: 70px;
  color: #fff;
  font-weight: 400;
  text-align: center;
  white-space: pre-line;
  vertical-align: middle;
  user-select: none;
  padding: 0.375rem 0.75rem;
  font-size: 18px;
  line-height: 1.5;
  border-radius: 10px;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.header-btn:hover {
  border-color: #121317;
}

.header-img {
  background-image: url("../img/pro-vendas-header-bg.png");
  background-size: cover;
  padding-top: 10px;
  padding-bottom: 32px;
}

.flex-row {
  display: flex;
  flex-direction: row;
}

.header-texto {
  color: #fff;
  font-size: 24px;
  padding-top: 2%;
  text-align: left;
}

.header-content {
  padding-top: 10%;
  width: 100%;
  display: flex;
  flex-direction: row;
}

.header-images {
  width: 100%;
  display: flex;
  flex-direction: row;
}

.header-celular {
  width: 35%;
}

.header-mac {
  width: 65%;
}

/* COMPONENT SOBRE / FERRAMENTAS */
.sobre {
  color: #4a5575;
}

.ferramentas-div {
  width: 100%;
  display: flex;
  flex-direction: row;
}

.sobre-subtitle {
  color: #6c757d;
}

.sobre-titulo {
  color: #4a5575;
  font-weight: 500;
  font-size: 25px;
  text-align: left;
}

.sobre-conteudo {
  padding-top: 15px;
  color: #6c757d;
  font-size: 17.5px;
  text-align: left;
}

.sobre-mac {
  width: 100%;
}

.sobre-btn {
  margin: 5px;
  background-color: #dbdbdb;
  width: 250px;
  color: #121317;
  font-weight: 400;
  text-align: center;
  white-space: pre-line;
  vertical-align: middle;
  user-select: none;
  padding: 0.375rem 0.75rem;
  font-size: 18px;
  line-height: 1;
  border-radius: 10px;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.sobre-btn:hover {
  border-color: #121317;
  color: #fff;
}

.sobre-selected {
  background-color: #121317;
  color: #fff;
}

/* COMPONENT DEPOIMENTOS */

.path-efeito {
  fill: #f9fdff;
}

.depoimentos {
  margin-top: 5%;
  margin-bottom: 3%;
}

.depoimento-card {
  display: flex;
  flex-direction: row;
}

.depoimento-avatar {
  margin-top: 10%;
  margin-left: 15%;
  min-width: 140px !important;
  width: 140px !important;
  height: 140px !important;
}

.blockquotes {
  width: 100%;
  padding: 0;
}


.blockquotes:before {
  /* background-image: url("../img/quote.png");
  background-size: cover;
  background-repeat: no-repeat;
  content: '';
  font-size: 60px;
  position: absolute;
  right: -15px;
  width: 50px;
  height: 45px; */
  display: none;
}

.depoimento-titulo {
  margin-top: 2%;
  display: flex;
  flex-flow: row-reverse;
}

.depoimento-conteudo {
  color: #6c757d;
  width: 77%;
  font-size: 14px;
  margin-top: 3%;
  margin-left: 15%;
  text-align: justify;
}

.depoimento-nome {
  padding-left: 15%;
  margin-right: 4%;
  font-size: 23px;
  font-weight: 600;
  color: #4a5575;
}

.depoimento-funcao {
  font-size: 17px;
  font-weight: 500;
}

.image-valores {
  padding-top: 50px;
}

/* FOOTER */

.pro-vendas-logo-footer {
  margin-top: 15%;
  max-width: 320px;
}

.footer-contato-div {
  margin-left: 0px;
}

.wpp-fab {
  bottom: 0;
  right: 0;
  display: flex;
  position: fixed;
  z-index: 9999;
  width: 50px;
  margin-right: 15px;
  margin-bottom: 15px;
  cursor: pointer;
}

.footer-title {
  color: #fff;
  font-size: 24px;
  font-weight: bold;
  margin-left: -20px;
}

.footer-subtitles {
  padding-top: 7px;
  font-weight: 100;
  color: #fff;
  font-size: 18px;
  white-space: nowrap;
}

.footer-email {
  padding-bottom: 10px;
  font-weight: 100;
  color: #fff;
  font-size: 18px;
}

.footer-wpp {
  padding-right: 5px;
  cursor: pointer;
}

.footer-endereco {
  padding-left: 2%;
  white-space: normal;
}

.social-media {
  padding-right: 10px;
  font-size: 29px;
  color: #fff;
}

.social-media:hover {
  color: #bdbdbd;
}

.social {
  margin-left: 30px;
}

.icon-contato {
  padding-top: 0px;
  padding-right: 8px;
  font-size: 29px;
  color: #fff;
}

.copyright {
  padding-right: 10%;
}

/* CAROUSEL ARROWS */

.slick-next,
.slick-prev {
  height: 21.5px;
  width: 21px;
  border-radius: 50%;
  top: 40%;
  background-color: #121c3a;
}
.slick-next::before,
.slick-prev::before {
  color: white ;
  font-size: 30px;
  opacity: 1;
  margin-left: -3px;
  line-height: 0.95;
}
.slick-arrow:hover {
  background-color: #121c3a !important;
}
.slick-arrow:focus {
  background-color: #121c3a !important;
}
.slick-prev {
  z-index: 1;
  left: -2%;
}
.slick-next {
  z-index: 1;
  right: 0%;
}

/* YOUTUBE VIDEO */
.youtube {
 padding: 10% 10% 10% 10%;
}

.youtube-iframe {
  height: 500px;
}

/* VALORES */

.valores-bg {
  background-color: #edf1f5;
}

.titulo-valores {
  padding-bottom: 5%;
}

.texto-valores {
  padding-left: 5%;
}

.trabalhe-btn {
  width: 25%;
  height: 60px;
}

.btn-lighter {
  background-color: #4290ca !important;
}

.div-btnform {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
}

.btn-disabled {
  pointer-events: none;
}

.selo-valores {
  padding-right: 5%;
}

.form-demonstracao {
  margin-top: -4%;
}

.recaptcha-div {
  display: flex;
  align-items: center;
  justify-content: center;
}

@media screen and (min-width: 801px) and (max-width: 1024px) {

  .header-btn {
    width: 200px;
    height: 70px;
  }

  .header-div-buttons {
    width: 100%;
    padding-top: 10px;
    padding-bottom: 10px;
  }

  .header-content {
    padding-top: 10%;
    width: 100%;
    display: flex;
    flex-direction: row;
  }

  .header-btn-left {
    float: right;
  }

  .sobre-btn {
    width: 220px;
  }

  .youtube-iframe {
    height: 500px;
  }

  .sobre-mac {
    padding-top: 0%;
  }

  .image-valores {
    padding-top: 15px;
    height: 240px;
  }

  .selo-valores {
    margin-left: 5%;
    width: 30%;
  }

  .pro-vendas-logo-footer {
    padding-top: 15%;
    width: 285px;
  }

  .footer-endereco {
    padding-left: 4%;
    width: 80%;
  }

  .depoimento-card {
    padding-right: 15%;
  }

  .header-img {
    height: 700px !important;
  }

}


@media screen and (min-width: 451px) and (max-width: 800px) {

  .div-btnform {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
  }
    
  .header-btn {
    width: 200px;
    height: 70px;
  }

  .header-div-buttons {
    width: 100%;
    padding-top: 10px;
    padding-bottom: 10px;
    margin-right: 5%;
  }

  .header-content {
    padding-top: 10%;
    width: 100%;
    display: flex;
    flex-direction: column;
  }

  .header-btn-left {
    float: right;
  }

  .sobre-btn {
    width: 250px;
  }

  .youtube-iframe {
    height: 340px;
  }

  .sobre-mac {
    padding-top: 10%;
  }

  .image-valores {
    padding-top: 15px;
    height: 240px;
  }

  .selo-valores {
    width: 30%;
  }

  .pro-vendas-logo-footer {
    padding-top: 35%;
    width: 200px;
  }

}


/* MAX WIDTH 450 */

@media screen and (max-width: 450px) {
  .wrapper {
    width: 100% !important;
  }

  .div-btnform {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }

  .header-content {
    padding-top: 25%;
    width: 100%;
    display: flex;
    flex-direction: column;
  }

  .pro-vendas-logo-footer {
    width: 300px;
  }

  #ferramentas {
    width: 95%;
  }

  .depoimentos-inner {
    width: 95%;
  }

  .demonstracao-inner {
    width: 90%;
  }

  .valores-inner {
    width: 95%;
  }

  .depoimento-card {
    width: 70% !important;
    height: 40% !important;
  }

  .titulo-valores {
    font-size: 35px;
  }

  .youtube-iframe {
    height: 160px;
  }

  .image-valores {
    padding-top: 15px;
    height: 240px;
  }

  .selo-valores {
    display: none;
  }

  .header-btn {
    width: 160px;
    height: 70px;
  }

  .header-div-buttons {
    width: auto;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-right: 5px;
  }

  .header-h1 {
    padding-left: 20%;
    padding-right: 20%;
  }

  .header-texto {
    color: #fff;
    font-size: 17px;
    padding-top: 0;
    text-align: left;
  }
  
  h1 {
    font-size: 35px;
  }

  .sobre-titulo {
    font-size: 18px;
  }

  .sobre-conteudo {
    font-size: 17px;
  }

  .sobre-conteudo-div {
    padding: 10% 5% 10% 10%;
  }

  .sobre-subtitle {
    font-size: 18px;
    text-align: justify;
    padding: 0% 5% 0% 5%;
  }

  .sobre-mac {
    padding: 0% 5% 0% 5%;
  }

  .depoimentos-subtitle {
    padding: 0% 5% 0% 5%;
    text-align: justify;
  }

  .depoimento-resultado {
    text-align: center;
    padding-top: 10%;
  }

  .blockquotes {
    width: 100%;
    padding: 0;
  }

  .blockquotes:before {
    display: none;
  }
  
  .depoimento-card {
    width: 100% !important;
    padding-left: 2%;
    padding-right: 0%;
  }

  .depoimento-conteudo {
    color: #6c757d;
    font-size: 16px;
    margin-top: 3%;
    margin-left: 0%;
    width: 100%;
    text-align: justify;
  }

  .depoimento-pessoa {
    padding: 4% 4% 10% 4%;
  }

  .trabalhe-btn {
    width: 90%;
  }

  .footer-title {
    padding-top: 10%;
    margin-left: 0px;
  }

  .footer {
    padding-top: 0;
  }

  .footer-subtitles {
    padding-left: 5%;
  }

  .footer-endereco {
    padding-left: 7%;
  }

  .depoimento-avatar {
    margin-left: 30%;
  }

}

@media screen and (max-width: 375px) {
  .header-btn {
    width: 140px;
    height: 70px;
  }
  
}

@media screen and (max-width: 320px) {
  .header-btn {
    width: 120px;
    height: 70px;
    font-size: 15px;
  }

  .header-div-buttons {
    width: auto;
    padding-top: 2px;
    padding-bottom: 5px;
    padding-right: 5px;
  }

  .header-h1 {
    padding-left: 10%;
    padding-right: 10%;
  }

  .footer-endereco {
    width: 80%;
    white-space: normal;
  }
}

@media screen and (min-width: 1360px) {
  .header-content {
    margin-top: 7%;
  }
}

@media screen and (min-width: 1500px) {
  .header-content {
    margin-top: 10%;
  }
}

@media screen and (min-width: 1800px) {
  .header-content {
    margin-top: 20%;
  }
}